<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#3F033B"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showsnackbar" color="#3F033B" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: white">
            {{ msg }}
          </span>
        </v-flex>

        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #000">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex xs12>
        <v-layout wrap>
          <v-flex xs12 sm10 text-left>
            <span class="mainHeader">OLD GOLD</span>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12 py-6 text-left>
        <v-card
          tile
          elevation="0"
          class="rounded-lg"
          :color="$route.name == 'Dashboard' ? 'transparent' : '#FFFFFF'"
          :flat="$route.name == 'Dashboard' ? true : false"
        >
          <v-layout wrap pa-4>
            <v-flex
              xs12
              pb-3
              pb-md-0
              style="font-family: DmSansBold; font-size: 25px"
              >AFTER ACIDING</v-flex
            >
          </v-layout>
          <v-flex xs12 sm10 md10>
          <v-layout wrap class="px-1">
            <v-flex xs10 sm3 md3 px-3 pb-3 pb-md-0>
              <v-flex xs12 pb-1 class="subhead3">Thankam ReturnDate :</v-flex>
              <v-menu
                ref="menu1"
                v-model="menu1"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date"
                    placeholder="Date1"
                    outlined
                    color="#b234a9"
                    hide-details=""
                    dense
                    append-icon="mdi-calendar-multiselect"
                    v-bind="attrs"
                    v-on="on"
                    clearable
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date"
                  color="#571964"
                  no-title
                  @input="menu1 = false"
                ></v-date-picker>
              </v-menu>
            </v-flex>
            <v-flex xs10 sm3 md3 pl-2 px-3 pb-3 pb-md-0>
              <v-flex xs12 pb-1 class="subhead3">Batch No :</v-flex>
              <v-autocomplete
                color="#b234a9"
                v-model="batcnoselect"
                placeholder="Select Batch no"
                item-text="batchno"
                item-value="_id"
                outlined
                hide-details
                dense
              ></v-autocomplete>
            </v-flex>
          </v-layout>
          </v-flex>
          <v-layout wrap>
            <v-flex xs10>
              <v-layout wrap class="py-2 px-1">
                <v-flex xs12 sm3 md3 px-3 pb-3 pb-md-0 py-2>
                  <v-flex xs12 pb-1 class="subhead3">Aciding Date :</v-flex>
                  <v-menu
                    ref="menu2"
                    v-model="menu2"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="date2"
                        placeholder="Date"
                        outlined
                        color="#b234a9"
                        hide-details=""
                        dense
                        append-icon="mdi-calendar-multiselect"
                        v-bind="attrs"
                        v-on="on"
                        clearable
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="date2"
                      color="#571964"
                      no-title
                      @input="menu2 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-flex>
                <v-flex xs12 sm3 md3 pl-2 px-3 pb-3 pb-md-0 py-2>
                  <v-flex xs12 pb-1 class="subhead3"
                    >Before Aciding (gm) :</v-flex
                  >
                  <v-text-field
                    disabled
                    v-model="beforeaciding"
                    outlined
                    color="#b234a9"
                    dense
                    hide-details
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm3 md3 pl-2 px-3 pb-3 pb-md-0 py-2>
                  <v-flex xs12 pb-1 class="subhead3">After Aciding :</v-flex>
                  <v-text-field
                    v-model="Afteraciding"
                    outlined
                    color="#b234a9"
                    dense
                    hide-details
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm3 md3 pl-2 px-3 pb-3 pb-md-0 py-2>
                  <v-flex xs12 pb-1 class="subhead3"
                    >Thankam Boarding Rate :</v-flex
                  >
                  <v-text-field
                    v-model="ThankamBoardrate"
                    outlined
                    color="#b234a9"
                    dense
                    hide-details
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 md2 sm2 align-self-end pb-3>
              <v-btn @click="validate()" color="#3F053C" dark 
                ><v-icon>mdi-check</v-icon></v-btn
              >
            </v-flex>
          </v-layout>

          <v-layout wrap py-2>
            <v-flex xs12 text-left>
              <v-card
                class="rounded-lg"
                elevation="0"
                :color="$route.name == 'Dashboard' ? 'transparent' : '#FFFFFF'"
                :flat="$route.name == 'Dashboard' ? true : false"
              >
                <v-layout wrap pa-4>
                  <v-flex xs12>
                    <v-simple-table
                      style="
                        border-bottom: 0.5px solid #e6e6e6 !important
                    ;
                      "
                    >
                      <thead>
                        <tr>
                          <th class="tablefont tble">
                            <b>S.No.</b>
                          </th>
                          <th class="tablefont tble">
                            <b>Batch No</b>
                          </th>
                          <th class="tablefont tble">
                            <b>Aciding Date</b>
                          </th>
                          <th class="tablefont tble">
                            <b>Return Date</b>
                          </th>
                          <th class="tablefont tble">
                            <b>Open Stock (gm)</b>
                          </th>
                          <th class="tablefont tble">
                            <b>Net weight</b>
                          </th>
                        </tr>
                      </thead>
                      <tbody class="bordered-table">
                        <tr>
                          <td>
                            <span>s</span>
                          </td>
                          <td>
                            <span>s</span>
                          </td>
                          <td>
                            <span>s</span>
                          </td>
                          <td>
                            <span>s</span>
                          </td>
                          <td>
                            <span>s</span>
                          </td>
                          <td>
                            <span>s</span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span>s</span>
                          </td>
                          <td>
                            <span>s</span>
                          </td>
                          <td>
                            <span>s</span>
                          </td>
                          <td>
                            <span>s</span>
                          </td>
                          <td>
                            <span>s</span>
                          </td>
                          <td>
                            <span>s</span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span>s</span>
                          </td>
                          <td>
                            <span>s</span>
                          </td>
                          <td>
                            <span>s</span>
                          </td>
                          <td>
                            <span>s</span>
                          </td>
                          <td>
                            <span>s</span>
                          </td>
                          <td>
                            <span>s</span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span>s</span>
                          </td>
                          <td>
                            <span>s</span>
                          </td>
                          <td>
                            <span>s</span>
                          </td>
                          <td>
                            <span>s</span>
                          </td>
                          <td>
                            <span>s</span>
                          </td>
                          <td>
                            <span>s</span>
                          </td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                  </v-flex>
                </v-layout>
                <v-layout wrap align-center justify-end class="px-2">
                  <v-flex xs6 sm2 md2 pl-0 pl-lg-3 py-2 class="subhead3">
                    Total weight(gm):
                  </v-flex>
                  <v-flex xs6 sm2 md2 pr-0 pr-lg-2 py-2>
                    <v-text-field
                      outlined
                      dense
                      color="#b234a9"
                      hide-details=""
                      disabled
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout wrap justify-end>
                  <v-flex xs12 sm4 md4 py-4 text-right pr-4 align-self-baseline>
                    <v-layout wrap justify-end>
                      <v-flex xs7 ms5 md5>
                        <v-btn dark color="#3F053C" min-width="140px"
                          >Save</v-btn
                        >
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
// import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showsnackbar: false,
      timeout: 2000,
      msg: null,
      username: null,
      keyword: null,
      name: null,
      address: null,
      phone: null,
      email: null,
      showField1: false,
      showField2: false,
      showField3: false,
      showField4: false,
      row: null,
      date1: null,
      menu1: false,
      date2: null,
      menu2: false,
      checkbox: true,
      batchno: null,
      ThankamBoardrate: null,
      Afteraciding: null,
      beforeaciding: null,
      batcnoselect: null,
    };
  },
  methods: {
    validateInput() {
      if (!this.email) {
        this.msg = "Please provide email";
        this.showsnackbar = true;
        return;
      } else if (!this.name) {
        this.msg = "Please provide name";
        this.showsnackbar = true;
        return;
      } else if (!this.phone) {
        this.msg = "Please provide phone";
        this.showsnackbar = true;
        return;
      } else if (!this.username) {
        this.msg = "Please provide username";
        this.showsnackbar = true;
        return;
      } else if (!this.address) {
        this.msg = "Please provide address";
        this.showsnackbar = true;
        return;
      } else {
        this.stocklist();
      }
    },
    toggleFields() {
      this.showField1 = !this.showField1;
      this.showField2 = !this.showField2;
      this.showField3 = !this.showField3;
      this.showField4 = !this.showField4;
    },
    // stocklist() {
    //   axios({
    //     url: "/stock/getalllist",
    //     method: "get",
    //     data: {
    //       username: this.username,
    //       email: this.email,
    //       jewelleryName: this.name,
    //       phone: this.phone,
    //       location: this.address,
    //     },
    //     headers: {
    //       "x-auth-token": localStorage.getItem("token"),
    //     },
    //     params: {
    //       page: this.currentpage,
    //       limit: this.limit,
    //       from: this.from,
    //       to: this.to,
    //     },
    //     // params: {
    //     //   "x-auth-token": localStorage.getItem("token"),
    //     // },
    //   })
    //     .then((response) => {
    //       this.appLoading = false;
    //       if (response.data.status == true) {
    //         this.array = response.data.data;
    //         this.pages = Math.ceil(response.data.totalLength / this.limit);
    //       } else {
    //         this.msg = response.data.msg;
    //         this.showsnackbar = true;
    //       }
    //     })
    //     .catch((err) => {
    //       this.appLoading = false;
    //       this.ServerError = true;
    //       console.log(err);
    //     });
    // },
  },
};
</script>
